import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import BASEURL from "../../Config/global";
import { setAccessToken } from "../../Util/authHeader";

import { useDispatch } from "react-redux";
import { setUserDetails } from "../../Store/Slices/UserSlice";

import AuthLayout from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";

import "./style.css";
import VerifyModal from "./VerifyModal";
import { crossIcon } from "../../Assets/svg";
import { Modal } from "react-bootstrap";


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loading, setLoading] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showHouseHoldModal, setShowHouseHoldModal] = useState(false);
  const [showCompleteTermInDaysModal, setShowCompleteTermInDaysModal] = useState(false);

  // Clear Session while doing refresh
  useEffect(() => {
    const clearStorage = () => {
      localStorage.clear();
      sessionStorage.clear();
    };
    window.addEventListener('beforeunload', clearStorage);
    return () => {
      window.removeEventListener('beforeunload', clearStorage);
    };
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.password.length >= 6) {
      const formDataToSend = new FormData();
      formDataToSend.append("username", formData.email?.trim());
      formDataToSend.append("password", formData.password);
      formDataToSend.append("login_platform", "web");

      const response = await axios.post(
        `${BASEURL}/api/user/login/`,
        formDataToSend
      );

      if (!response.data.error) {
        localStorage.setItem("user", JSON.stringify(response?.data?.data[0]));
        localStorage.setItem("is_primary", JSON.stringify(response?.data?.data[0]?.is_primary));
        localStorage.setItem("userId", JSON.stringify(response?.data?.data[0]?.id));
        localStorage.setItem("user_country", JSON.stringify(response?.data?.data[0]?.country));
        dispatch(setUserDetails(response.data.data[0]));
        setLoading(false);
        setLoginError({ error: false, text: "" });
        if (response.data.data[0].is_exceed) {
          setShowHouseHoldModal(true)
        } else {
          const { status, subscription_status, user_route, coupon } = response?.data?.data[0];
          if (status === "lead" || subscription_status === 'ReSubscription_Failed' || subscription_status === 'incomplete') {
            if (user_route && user_route != "signup") {
              // debugger
              window.sessionStorage.setItem("isPromoUser", true);
              if (coupon && coupon.hasOwnProperty("id") && coupon?.duration_type === "term_in_days") {
                setShowCompleteTermInDaysModal(true);
              } else {
                navigate(user_route);
              }
            } else {
              navigate("/subscription");
            }
            return;
          } else if (status === "coupon_lead") {
            if (user_route) {
              window.sessionStorage.setItem("isPromoUser", true);
              if (coupon && coupon.hasOwnProperty("id")  && coupon?.duration_type === "term_in_days") {
                setShowCompleteTermInDaysModal(true);
              } else {
                navigate(user_route);
              }
              return;
            }
          } else if (status === "primary" || status === "coupon" || status === "coupon_sub_user" || status === "sub_user") {
            const token = response.data.data[0].token;
            setAccessToken(token);
            navigate("/home");
          } else {
            setLoginError({
              error: true,
              text: "Something went wrong. Please try again later.",
            });
          }
        }
      } else {
        setLoading(false);
        setLoginError({
          error: true,
          text: response.data.message
        });
        if (!response?.data?.data[0].verify) {
          dispatch(setUserDetails(response?.data?.data[0]));
          setShowVerifyModal(true);
        }
      }

    } else {
      setLoading(false);
      setLoginError({
        error: true,
        text: "Password must contain 6 characters",
      });
    }
  };

  return (
    <>
      <AuthLayout pagename="login" login={true}>
        <form>
          <div className="row">
            <div className="col-12">
              <CustomInput
                label="Email"
                required
                id="email"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  });
                }}
              />
            </div>
            <div className="col-12">
              <CustomInput
                label="Password"
                required
                id="pass"
                type="password"
                labelClass="mainLabel"
                inputClass="mainInput"
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    password: event.target.value,
                  });
                }}
              />
            </div>
            {loginError.error == true && (
              <div className="col-12">
                <p className="smallText lightColor">{loginError.text}</p>
              </div>
            )}
            <div className="col-12 text-end">
              <Link to={"/forgot-password"} className={"authFormLinks"}>
                Forgot Password?
              </Link>
            </div>
          </div>
          <div className="row mt-4 text-center">
            <div className="col-12">
              {
                loading ? (

                  <button class="primaryButton customButton">
                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (

                    < CustomButton
                      variant="primaryButton"
                      text="Login"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>
        </form>
      </AuthLayout>

      {showVerifyModal && <VerifyModal />}


      {showHouseHoldModal &&
        <div class="household-modal">
          <div class="household-modal-inner">
            <div className="text-end">
              <button
                className="closeButton notButton ms-auto"
                onClick={() => { setShowHouseHoldModal(false); }}
              >
                <img src={crossIcon} alt="" />
              </button>
            </div>
            <p class="mb-4 lh-16 font-16">You've been away from your primary household account for over 30 days.</p>
            <p class="mb-4 lh-16 font-16">You have two options:</p>
            <p class="mb-4 lh-16 font-16">1. Log in from your primary household location to re-verify your sub account for another 30 days.</p>
            <p class="mb-4 lh-16 font-16">2. Create your primary account with a monthly or yearly subscription {`${' '}`}
              <Link to={"/subscription"} class="lh-16 font-16">Click here</Link>
            </p>
            <p class="lh-16 font-16">For details visit: </p>
            <Link to="https://relaxscofa.com/faq/#sub-accounts" class="lh-16 font-16">RelaxScofa.com/faq</Link>
          </div>
        </div>
      }

      {/* term in days modal */}
      <Modal
        show={showCompleteTermInDaysModal}
        centered
        className="success-modal blur-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="p-5">
            <h2 className="font-16 lh-16 mt-5 fw-normal text-center">Your term in days has been completed.</h2>
            <div className='text-center mt-4'>
              <CustomButton
                variant="primaryButton"
                text="Payment"
                onClick={() => navigate('/promo')}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Login;
